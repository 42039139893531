<style>
.input-readonly > div > .vs-inputx {
  background-color: #e6e6e6;
}
</style>
<template>
  <div class="vx-col w-5/6 ml-auto mr-auto">
    <span>
      <h4 class="mb-5">Supplier Credit Note</h4>
    </span>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Supplier Credit Note Number"
          name="Supplier Credit Note Number"
          v-model="cn_number"
          placeholder="Supplier Credit Note Number"
          readonly
        />
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <label class="vs-input--label">Supplier Credit Note Date</label>
        <!-- <datepicker
          class="w-full"
          :inline="false"
          name="Invoice Date"
          v-model="cn_date"
          placeholder="Select Supplier Credit Note Date"
          readonly
        ></datepicker> -->
        <vs-input
          class="w-full input-readonly"
          :inline="false"
          name="Invoice Date"
          v-model="cn_date"
          placeholder="Select Supplier Credit Note Date"
          readonly
        />
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Tax Return Number"
          name="Tax Return Number"
          v-model="tr_number"
          @keypress="isNumberAndFormat($event)"
          placeholder="Tax Return Number"
          readonly
        />
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <label class="vs-input--label">Tax Return Date</label>
        <!-- <datepicker
          class="w-full"
          :inline="false"
          name="Invoice Date"
          v-model="tr_date"
          placeholder="Select Tax Return Date"
          readonly
        ></datepicker> -->
        <vs-input
          class="w-full input-readonly"
          :inline="false"
          name="Invoice Date"
          v-model="tr_date"
          placeholder="Select Tax Return Date"
          readonly
        />
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <label class="vs-input--label">Settlement Method</label>
        <multiselect
          class="selectExample"
          v-model="settlement.selected"
          :options="settlement.options"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          disabled
        >
        </multiselect>
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <label class="vs-input--label">Posting Date</label>
        <!-- <datepicker
          class="w-full"
          v-validate="'required'"
          :inline="false"
          name="DN Posting Date"
          v-model="posting_date"
          placeholder="Select Posting Date"
          :disabled-dates="{ from: new Date() }"
          readonly
        ></datepicker>
        <span
          class="text-danger text-sm"
          v-if="posting_date == null && status_submit"
          >This field is required</span
        > -->
        <vs-input
          class="w-full input-readonly"
          v-validate="'required'"
          :inline="false"
          name="DN Posting Date"
          v-model="posting_date"
          placeholder="Select Posting Date"
          readonly
        />
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="SR Code"
          name="SR Code"
          v-model="sr_code"
          placeholder="SR Code"
          readonly
        />
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="GRR Code"
          name="GRR Code"
          v-model="grr_code"
          placeholder="GRR Code"
          readonly
        />
      </div>
    </div>

    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="DN Number"
          name="DN Number"
          v-model="dn_number"
          placeholder="DN Number"
          readonly
        />
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Notes"
          name="Notes"
          v-model="notes"
          placeholder="Notes"
          readonly
        />
      </div>
    </div>
    <div class="vx-row mb-2 ml-1">
      <h5>Line Item</h5>
    </div>
    <vs-table stripe border description :sst="true" :data="table.data">
      <template slot="thead">
        <vs-th>Item</vs-th>
        <vs-th>Price</vs-th>
        <vs-th>Damaged Quantity</vs-th>
        <vs-th>Tax Rate (%)</vs-th>
        <vs-th>Return</vs-th>
        <vs-th>Debit Note</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr
          :data="tr"
          :key="indextr"
          v-for="(tr, indextr) in data"
          :class="data[indextr].class"
        >
          <vs-td :data="data[indextr].code">
            <div class="vx-row ml-0">
              <span class="w-5/5">{{ data[indextr].item_name }}</span
              ><br />
              <span class="w-5/5">{{ data[indextr].batch }}</span>
            </div>
          </vs-td>
          <vs-td>
            <div class="vx-row ml-0">
              {{
                parseFloat(
                  table.purchase_order.lines.filter((i) => {
                    return (i.item_unit_id = data[indextr].item_unit_id_return);
                  })[0].price_pcs
                )
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }}
            </div>
          </vs-td>
          <vs-td>
            <div class="vx-row ml-0">
              {{ data[indextr].damage_quantity }} {{ data[indextr].unit_name }}
            </div>
          </vs-td>
          <vs-td>
            <!-- <multiselect
              class="selectExample"
              v-model="data[indextr].tax_rate"
              :options="tax.options"
              :multiple="false"
              :allow-empty="false"
              :group-select="false"
              :max-height="160"
              :limit="4"
              :show-labels="false"
              placeholder=" Type to search"
              @input="onChangetax(indextr)"
              disabled
            >
            </multiselect> -->
            <vs-input
                v-validate="'required'"
                class="w-full mb-2 input-readonly"
                name="Value"
                label="Value"
                v-model="data[indextr].tax_rate"
                @input="formatNumber(indextr, 'tax_rate')"
                placeholder="Tax Rate"
                @keypress="isNumber($event)"
                readonly
              />
          </vs-td>
          <vs-td>
            <div class="vx-row ml-0">
              <vs-input
                class="w-full mb-2 input-readonly"
                name="Quantity"
                label="Quantity"
                :value="
                  data[indextr].return_quantity
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                "
                readonly
              />
              <vs-input
                class="w-full mb-2 input-readonly"
                name="Value"
                label="Value"
                :value="
                  parseFloat(data[indextr].return_value)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                "
                readonly
              />

              <vs-input
                class="w-full mb-2 input-readonly"
                name="Tax"
                label="Tax"
                :value="
                  parseFloat(data[indextr].return_tax)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                "
                readonly
              />
              <vs-input
                class="w-full mb-2 input-readonly"
                name="Total"
                label="Total"
                :value="
                  parseFloat(data[indextr].return_total)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                "
                readonly
              />
            </div>
          </vs-td>
          <vs-td>
            <div class="vx-row ml-0">
              <vs-input
                v-validate="'required'"
                class="w-full mb-2 input-readonly"
                name="Quantity"
                label="Quantity"
                v-model="data[indextr].dn_quantity"
                @input="formatNumber(indextr, 'dn_quantity')"
                placeholder="Quantity DN"
                @keypress="isNumber($event)"
                readonly
              />
              <span
                class="text-danger text-sm"
                v-if="data[indextr].dn_quantity == null && status_submit"
                >This field is required</span
              >
              <vs-input
                v-validate="'required'"
                class="w-full mb-2 input-readonly"
                name="Value"
                label="Value"
                v-model="data[indextr].dn_value"
                @input="formatNumber(indextr, 'dn_value')"
                placeholder="DN Value"
                @keypress="isNumber($event)"
                readonly
              />
              <span
                class="text-danger text-sm"
                v-if="data[indextr].dn_value == null && status_submit"
                >This field is required</span
              >

              <vs-input
                v-validate="'required'"
                class="w-full mb-2 input-readonly"
                name="Tax"
                label="Tax"
                v-model="data[indextr].dn_tax"
                @input="formatNumber(indextr, 'dn_tax')"
                placeholder="DN Tax"
                @keypress="isNumber($event)"
                readonly
              /><span
                class="text-danger text-sm"
                v-if="data[indextr].dn_tax == null && status_submit"
                >This field is required</span
              >
              <vs-input
                class="w-full mb-2 input-readonly"
                name="Total"
                label="Total"
                v-model="data[indextr].dn_total"
                placeholder="DN Total"
                readonly
              />
            </div>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="DPP"
          name="DPP"
          v-model="dpp"
          readonly
        />
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Tax"
          name="Tax"
          v-model="total_ppn"
          readonly
        />
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Total"
          name="Total"
          v-model="total_dn"
          readonly
        />
      </div>
    </div>
    <!-- <div class="vx-row mb-6 mt-6">
      <div class="vx-col w-full" style="margin-bottom: 150px">
        <vs-button class="mb-2" @click="handleSubmit"
          >Save & Approval</vs-button
        >
      </div>
    </div> -->
  </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import moment, { now } from "moment";
export default {
  components: {
    Datepicker,
  },
  props: {
    po_code: {
      type: String,
    },
    po_date: {
      type: String,
    },
    po_id: {
      type: Number,
    },
    po_virtual_code: {
      type: String,
    },
    purchase_receive_code: {
      type: String,
    },
    purchase_receive_id: {
      type: Number,
    },
    purchase_return_code: {
      type: String,
    },
    purchase_return_date: {
      type: String,
    },
    purchase_return_id: {
      type: Number,
    },
    received_date: {
      type: String,
    },
    supplier_address: {
      type: String,
    },
    supplier_city: {
      type: String,
    },
    supplier_code: {
      type: String,
    },
    supplier_contact_name: {
      type: String,
    },
    supplier_name: {
      type: String,
    },
    supplier_phone: {
      type: String,
    },
    warehouse_code: {
      type: String,
    },
    warehouse_id: {
      type: Number,
    },
    warehouse_name: {
      type: String,
    },
    dn_number: {
      type: String,
    },
    notes: {
      type: String,
    },
    source: {
      type: String,
    },
    sr_code: {
      type: String,
    },
    grr_code: {
      type: String,
    },
  },
  created() {},
  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        settlement: {
          options: ["Payment", "AP Offset"],
          // selected: "AP Offset",
        },
        tax: {
          options: [],
          selected: null,
        },
        search: "",
        offset: 0,
        limit: 10,
        status_submit: false,
        table: {
          data: [],
          purchase_order: {},
        },
        // dn_number: null,
        cn_number: null,
        cn_date: null,
        posting_date: null,
        tr_number: null,
        tr_date: null,
      };
    },
    handleClose() {
      window.scrollTo(0, 0);
      this.$emit("close");
    },
    paramData() {
      this.table.data.map((r) => {
        r.price_pcs = r.price_pcs.toString();
      });
      return {
        dn_number: this.dn_number,
        territory_id: this.$userLogin.territory_id,
        territory_area_id: this.$userLogin.territory_area_id,
        po_code: this.po_code,
        po_date: this.po_date,
        po_id: this.po_id,
        settlement: this.settlement.selected,
        po_virtual_code: this.po_virtual_code,
        purchase_receive_code: this.purchase_receive_code,
        purchase_receive_id: this.purchase_receive_id,
        purchase_return_code: this.purchase_return_code,
        purchase_return_date: this.purchase_return_date,
        purchase_return_id: this.purchase_return_id,
        received_date: this.received_date,
        supplier_address: this.supplier_address,
        supplier_city: this.supplier_city,
        supplier_code: this.supplier_code,
        supplier_contact_name: this.supplier_contact_name,
        supplier_name: this.supplier_name,
        supplier_phone: this.supplier_phone,
        warehouse_code: this.warehouse_code,
        warehouse_id: this.warehouse_id,
        warehouse_name: this.warehouse_name,
        supplier_cn_number: this.cn_number,
        supplier_cn_date: this.cn_date,
        tax_return_number: this.tr_number,
        tax_return_date: this.tr_date,
        data: this.table.data,
        sr_code: this.sr_code,
        grr_code: this.grr_code,
        status: "0",
        type: "Return",
        posting_date: this.posting_date,
        notes: this.notes,
      };
    },
    handleSubmit() {
      this.status_submit = true;
      this.$validator.validateAll().then((result) => {
        if (result) {
          console.log(this.paramData());
          this.$vs.loading();
          this.postData();
        }
      });
    },
    postData() {
      this.$http
        .post("/api/v1/debit-note/", this.paramData())
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    formatNumber(index, type) {
      console.log(this.table.data, "data");
      if (this.table.data[index][type].replace(/,/g, "").length < 16) {
        var str = this.table.data[index][type].toString().split(".");
        if (str.length > 2) {
          this.table.data[index][type] = this.table.data[index][type]
            .toString()
            .replace(/\.+$/, "");
        }

        if (str.length == 2) {
          if (str[1].length > 3) {
            this.table.data[index][type] = this.table.data[index][type]
              .toString()
              .slice(0, -1);
          }
        }

        this.table.data[index][type] = this.table.data[index][type]
          .replace(/,/g, "")
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        if (type == "dn_quantity") {
          let quantity = this.table.data[index]["dn_quantity"];
          if (quantity == "") {
            quantity = "0";
          }
          this.table.data[index]["dn_value"] = (
            parseFloat(quantity.replace(/,/g, "")) *
            parseFloat(this.table.data[index]["price_pcs"].replace(/,/g, ""))
          )
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
        if (type == "tax_rate") {
          if (this.table.data[index]["tax_rate"] > 100) {
            this.table.data[index]["tax_rate"] = 100;
          }
        }
        if (type == "dn_value" || type == "dn_quantity" || type == "tax_rate") {
          this.table.data[index]["dn_tax"] = Math.floor(
            parseFloat(
              parseFloat(this.table.data[index]["dn_value"].replace(/,/g, "")) *
                (this.table.data[index]["tax_rate"] / 100)
            )
          )
            .toFixed(0)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
        if (type == "dn_tax") {
          this.table.data[index]["dn_tax"] = Math.floor(
            this.table.data[index]["dn_tax"].replace(/,/g, "")
          )
            .toFixed(0)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
        this.table.data[index]["dn_total"] = (
          parseFloat(this.table.data[index]["dn_value"].replace(/,/g, "")) +
          parseFloat(
            Math.floor(this.table.data[index]["dn_tax"].replace(/,/g, ""))
          )
        )
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        // if (type == "dn_value" || type == "dn_tax") {
        //   this.table.data[index]["dn_total"] = (
        //     parseFloat(this.table.data[index]["dn_value"].replace(/,/g, "")) +
        //     parseFloat(this.table.data[index]["dn_tax"].replace(/,/g, ""))
        //   )
        //     .toFixed(2)
        //     .toString()
        //     .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        // }
      } else {
        this.table.data[index][type] = this.table.data[index][type]
          .toString()
          .slice(0, -1);
      }
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    isNumberAndFormat: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;

      if (charCode != 8 && this.tr_number != null) {
        if (this.tr_number.length == 3) {
          this.tr_number = this.tr_number + ".";
        } else if (this.tr_number.length == 7) {
          this.tr_number = this.tr_number + "-";
        } else if (this.tr_number.length == 10) {
          this.tr_number = this.tr_number + ".";
        } else if (this.tr_number.length > 18) {
          evt.preventDefault();
          // this.$nextTick(() => {
          //   let a = this.tr_number.toString().substr(0, 19)
          //   this.tr_number = a

          // })
        }
      }
    },
    autoSetSettlementMethod(supplier_id) {
      this.$vs.loading();
      console.log("autoSetSettlementMethod Supplier ID : ", supplier_id);
      this.$http
        .get("/api/v1/master/set-settlement-method", {
          // .get("/api/v1/set-settlement-method", {
          params: {
            order: "id",
            sort: "desc",
            supplier_id: supplier_id,
          },
        })
        .then((resp) => {
          console.log(resp);
          if (resp.code == 200) {
            if (resp.message == "Data not found") {
              // if not found, default AP Offset
              this.settlement.selected = "AP Offset";
            } else {
              this.settlement.selected = resp.data.SettlementMethod;
            }
          }
          this.$vs.loading.close();
        });
    },
    supplierChange(val) {
      console.log("supp", val);
      var supplier_id = val.ID ? val.ID : val.id;
      this.autoSetSettlementMethod(supplier_id);
    },

    getData() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/debit-note/line", {
          params: {
            purchase_return_id: this.purchase_return_id,
            debit_note_code: this.dn_number,
            po_id: this.po_id,
          },
        })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            // this.table.data = resp.data.records;
            console.log(resp.data);
            this.table.purchase_order = resp.data.purchase_order;
            this.autoSetSettlementMethod(resp.data.purchase_order.supplier_id); //tambahan

            this.cn_number = resp.data.debit_note.SupplierCNNumber
            this.cn_date = this.formatDate(resp.data.debit_note.SupplierCNDate)
            this.tr_number = resp.data.debit_note.TaxReturnNumber
            this.tr_date = this.formatDate(resp.data.debit_note.tr_date)
            this.posting_date = this.formatDate(resp.data.debit_note.PostingDate)

            resp.data.purchase_return.records.map((row) => {
              if (this.source == "import") {
                this.table.data.push({
                  ...row,
                  ...{
                    amount_uom: row.amount_uom,
                    batch: row.batch,
                    damage_quantity: row.damage_quantity,
                    item_name: row.item_name,
                    item_unit_id_recieve: row.item_unit_id_recieve,
                    item_unit_id_return: row.item_unit_id_return,
                    price_pcs: row.price_pcs.toString(),
                    unit_name: row.unit_name,
                    return_quantity: row.return_quantity.toString(),
                    return_value: row.return_value.toString(),
                    dn_quantity: row.return_quantity
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    dn_value: row.return_value
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ","),

                    return_tax: Math.floor(
                      row.return_value * (row.tax_rate / 100)
                    ).toString(),
                    return_total: (
                      Math.floor(row.return_value * (row.tax_rate / 100)) +
                      row.return_value
                    ).toString(),
                    dn_tax: Math.floor(row.return_value * (row.tax_rate / 100))
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    dn_total: (
                      Math.floor(row.return_value * (row.tax_rate / 100)) +
                      row.return_value
                    )
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                  },
                });
              } else {
                this.table.data.push({
                  ...row,
                  ...{
                    amount_uom: row.amount_uom,
                    batch: row.batch,
                    damage_quantity: row.damage_quantity,
                    item_name: row.item_name,
                    item_unit_id_recieve: row.item_unit_id_recieve,
                    item_unit_id_return: row.item_unit_id_return,
                    price_pcs: row.price_pcs.toString(),
                    return_quantity: row.return_quantity.toString(),
                    return_value: row.return_value.toString(),
                    return_tax: Math.floor(
                      row.return_value * (row.tax_rate / 100)
                    ).toString(),
                    return_total: (
                      Math.floor(row.return_value * (row.tax_rate / 100)) +
                      row.return_value
                    ).toString(),
                    unit_name: row.unit_name,
                  },
                });
              }
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    getOptionTax() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/tax", {
          params: {
            order: "rate",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.tax.options = [];
            var valueArr = resp.data.records.map(function (data) {
              return data.Rate;
            });
            this.tax.options = [...new Set(valueArr)];
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get tax option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    onChangetax(index) {
      console.log(this.table.data[index].tax_rate, "onChangetax");
      this.table.data[index].dn_tax = (
        this.table.data[index].return_value *
        (this.table.data[index].tax_rate / 100)
      )
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.table.data[index].dn_total = (
        parseFloat(
          this.table.data[index].return_value *
            (this.table.data[index].tax_rate / 100)
        ) + parseFloat(this.table.data[index].return_value)
      )
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    formatDate(value) {
      return moment(value).format("YYYY-MM-DD");
    },
  },
  mounted() {
    if (this.purchase_return_id) {
      this.getData();
      this.autoSetSettlementMethod();
    }
    this.getOptionTax();
  },
  computed: {
    dpp() {
      return this.table.data
        .map((item) => {
          if (isNaN(item.dn_value)) {
            var str = item.dn_value.toString().replace(/,/g, "");
            return parseFloat(str);
          } else {
            return parseFloat(item.dn_value);
          }
        })
        .reduce((prev, curr) => prev + curr, 0)
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    total_ppn() {
      return this.table.data
        .map((item) => {
          console.log(item.dn_tax, "dn_tax");
          if (isNaN(item.dn_tax)) {
            var str = item.dn_tax.toString().replace(/,/g, "");
            return parseFloat(str);
          } else {
            return parseFloat(item.dn_tax);
          }
        })
        .reduce((prev, curr) => prev + curr, 0)
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    total_dn() {
      return this.table.data
        .map((item) => {
          console.log(item);
          if (isNaN(item.dn_total)) {
            var str = item.dn_total.toString().replace(/,/g, "");
            return parseFloat(str);
          } else {
            return parseFloat(item.dn_total);
          }
        })
        .reduce((prev, curr) => prev + curr, 0)
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
  watch: {
    purchase_return_id() {
      Object.assign(this.$data, this.initialState());
      this.getData();
      this.autoSetSettlementMethod();
    },
  },
};
</script>
<style scoped>
.pagination {
  display: flex;
  margin: 0.25rem 0.25rem 0;
}

.pagination button {
  flex-grow: 1;
}

.pagination button:hover {
  cursor: pointer;
}

.horizontal-divider {
  border-top: 1px solid #c1bbff;
  padding: 5px;
  margin-top: 15px;
}
</style>
