<template>
  <div class="flex flex-col gap-6 mt-3">
    <vs-tabs>
        <vs-tab label="With Ref">
          <open-with></open-with>
        </vs-tab>
        <vs-tab label="Without Ref">
          <open-without></open-without>
        </vs-tab>
    </vs-tabs>
  </div>
</template>
<script>
  import OpenWith from "./open_with_ref";
  import OpenWithout from "./open_without_ref";

  export default {
    components: {
        "open-with":OpenWith,
        "open-without":OpenWithout,
    },

  }
</script>